<template>
  <div>
      <vue-header title="我的评价"/>
      <router-link :to="meal.pingjia_id==0?`/assessInfo/${meal.id}`:false" class="record-tool" v-for="meal in assess_list" v-bind:key="meal.id">
        <div class="record-box flex flex-y">
            <div class="record-title">
                {{timeSubmit(meal.created_date)}}
            </div>
            <div class="flex-1">
                <div>专项理疗：{{meal.disease.name}}</div>
            </div>
            <div class="green" v-if="meal.pingjia_id==0">未评价</div>
            <div class="green" v-else>已评价</div>
        </div>
        <div class="record-date flex">
            <div class="flex-1">套餐：{{meal.set_meal.name}}</div>
        </div>
      </router-link>
  </div>
</template>
<script>
import vueHeader from '@/components/header';
import { mapActions , mapState } from 'vuex';
import { titleFun } from '@/utils';
//let timer;
export default {
  name: 'mySubscribe',
  components:{
    vueHeader
  },
  created:function(){
      this.assessListMain({});
      titleFun('我的评价');
      //window.addEventListener('scroll', this.onSrollFun)
  },
  // destroyed: function () {
  //     window.removeEventListener('scroll', this.onSrollFun, false);
  // },
  computed:{
      ...mapState('my',['assess_list']),
  },
  methods:{
      // onSrollFun(){
      //       let _this = this;
      //       clearTimeout(timer);
      //       timer = setTimeout(()=>{
      //           let height = document.documentElement.scrollHeight-document.documentElement.scrollTop-document.documentElement.clientHeight;
      //           //获取滚动条到底部的距离
      //           if(height<1000){
      //               console.log(123,_this);
      //           }
      //       },1000)
      // },
      timeSubmit(value){
        let date = new Date(value);
        let y = date.getFullYear();
        let m = date.getMonth() + 1;
        m = m < 10 ? ('0' + m) : m;
        let d = date.getDate();
        d = d < 10 ? ('0' + d) : d;
        return `${y}-${m}-${d}`;
      },
      ...mapActions('my',['assessListMain'])
  }
}
</script>

<style lang="scss" scoped>
  @import './assess';
</style>